import { calculateTotalCost } from 'lib/utilities/calculateCost';
import React from 'react';
import { InfoOutlined, Check, Email } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { UserAuth } from 'provider/AuthProvider';

const AdminPricing = () => {
  const experienceRanges = [
    { range: '0-1', label: 'Entry Level', features: ['Basic skill assessment', 'Standard support', 'Email notifications'], recommended: false },
    { range: '2-3', label: 'Mid Level', features: ['Advanced skill assessment', 'Priority support', 'Real-time notifications'], recommended: true },
    { range: '4-5', label: 'Senior Level', features: ['Expert skill assessment', 'Premium support', 'Custom reporting'], recommended: false },
    { range: '6-7', label: 'Lead Level', features: ['Leadership assessment', 'Dedicated support', 'Advanced analytics'], recommended: false }
  ];

  const { coinPrice, experiencePrice } = UserAuth();

  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8 bg-gray-50">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-12">
          <div>
            <h1 className="text-4xl font-bold text-gray-900 mb-4">Pricing Plans</h1>
            <p className="text-xl text-gray-600">
              Choose the right plan based on your candidate's experience level
            </p>
          </div>
          <Link
            className="inline-flex items-center gap-2 px-6 py-3 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 transition-colors"
            to={'mailto:contact@neusort.com'}
            target='_blank'
          >
            <Email className="w-5 h-5" />
            Contact Us
          </Link>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12">
          {experienceRanges.map(({ range, label, features, recommended }) => {
            const cost = calculateTotalCost(1, range, experiencePrice, coinPrice);
            return (
              <div
                key={range}
                className={`relative bg-white rounded-xl shadow-sm border min-w-[280px] w-full ${recommended ? 'border-blue-400' : 'border-gray-300'
                  }`}
              >
                {recommended && (
                  <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                    <span className="bg-blue-600 text-white text-xs font-medium px-3 py-1 rounded-full">
                      Recommended
                    </span>
                  </div>
                )}
                <div className="p-6 h-full flex flex-col">
                  <div className="text-center mb-6 flex-grow">
                    <h3 className="text-xl font-semibold text-gray-900">{label}</h3>
                    <p className="text-sm text-gray-500 mt-1">{range} years experience</p>
                    <div className="mt-4 flex items-center justify-center">
                      <span className="text-3xl font-bold text-gray-900">{cost.totalCoins}</span>
                      <span className="text-gray-500 ml-2">coins</span>
                    </div>
                    <p className="text-sm text-gray-500 mt-1">₹{cost.totalAmount} per candidate</p>
                  </div>

                  <div className="space-y-3">
                    {features.map((feature, index) => (
                      <div key={index} className="flex items-center gap-2">
                        <Check className="w-4 h-4 text-blue-500 flex-shrink-0" />
                        <span className="text-sm text-gray-600">{feature}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="bg-white rounded-2xl p-8 shadow-sm border border-gray-200">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-2xl font-bold text-gray-900">Payment Schedule</h2>
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-600">1 Coin = </span>
              <span className="font-medium text-blue-600">₹{coinPrice}</span>
            </div>
          </div>
          <div className="space-y-4 mb-6">
            <div className="flex gap-4 p-5 items-center bg-gray-50 border border-gray-200 rounded-lg">
              <div className="w-8 h-8 bg-blue-50 rounded-full flex items-center justify-center flex-shrink-0 border border-blue-100">
                <span className="text-blue-600 font-medium">1</span>
              </div>
              <div>
                <h3 className="font-medium text-gray-800 mb-1">Create Job Posting</h3>
                <p className="text-sm text-gray-600">Upload candidates and coins will be held based on total candidates</p>
              </div>
            </div>
            <div className="flex gap-4 p-5 items-center bg-gray-50 border border-gray-200 rounded-lg">
              <div className="w-8 h-8 bg-blue-50 rounded-full flex items-center justify-center flex-shrink-0 border border-blue-100">
                <span className="text-blue-600 font-medium">2</span>
              </div>
              <div>
                <h3 className="font-medium text-gray-800 mb-1">Interview Scheduling</h3>
                <p className="text-sm text-gray-600">50% coins will be deducted when candidate schedules interview</p>
              </div>
            </div>
            <div className="flex gap-4 p-5 items-center bg-gray-50 border border-gray-200 rounded-lg">
              <div className="w-8 h-8 bg-blue-50 rounded-full flex items-center justify-center flex-shrink-0 border border-blue-100">
                <span className="text-blue-600 font-medium">3</span>
              </div>
              <div>
                <h3 className="font-medium text-gray-800 mb-1">Interview Completion</h3>
                <p className="text-sm text-gray-600">Remaining 50% coins will be deducted upon interview completion</p>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-3 p-4 bg-yellow-50 border border-yellow-100 rounded-lg">
            <InfoOutlined className="text-yellow-600 flex-shrink-0" style={{ fontSize: 20 }} />
            <div>
              <span className="font-medium text-yellow-800">Note:</span>
              <span className="text-sm text-yellow-800 ml-1">
                Any remaining hold amount will be automatically released back to your account when you close the job posting.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default AdminPricing;