import AdminNavbar from 'customComponents/AdminComponents/AdminNavbar'
import React from 'react'

const DashboardLayout = ({ component }) => {
  return (
    <>
      <AdminNavbar />
      <div className="flex flex-1 h-full w-full flex-col overflow-y-auto">
        {component}
      </div>
    </>
  )
}

export default DashboardLayout;