import { UserAuth } from "provider/AuthProvider";

export default function AdminHomeV1() {
  const { user } = UserAuth();
  return (
    <div className="flex flex-col overflow-y-auto">
      <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", alignItems: "center" }}>
        <div
          style={{
            backgroundColor: "white",
            padding: "20px 20px 20px",
            margin: "10px",
            borderRadius: "5px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
            display: "flex",
            gap: "20px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h1 style={{ fontWeight: "900" }}>{user.displayName}</h1>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ color: "grey", fontSize: "20px", fontWeight: "500" }}>{user.email}</p>
              <p style={{ color: "grey", fontSize: "15px", fontWeight: "500" }}>{user.uid}</p>
            </div>
          </div>
          <div style={{ height: "300px" }}>
            <img
              style={{ height: "100%", width: "100%", objectFit: "contain", borderRadius: "5px" }}
              src="https://cdn.pixabay.com/photo/2023/11/06/06/53/watermelon-8368960_1280.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
