import { Close, MonetizationOnOutlined, Send } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import { BASE_URL } from "lib/utilities/globalConstants";
import { tokenResult } from "lib/utilities/tokenResult";
import { UserAuth } from "provider/AuthProvider";
import React, { useState } from "react";
import { toast } from "react-toastify";

export default function AddCoinsModel() {
  const [formData, setFormData] = useState({
    companyId: "",
    coins: "",
  });
  const [open, setOpen] = useState(false);
  const [adding, setAdding] = useState(false);

  const { user } = UserAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "coins" && (!Number.isInteger(Number(value)) || Number(value) < 0)) {
      toast.error("Coins must be a positive integer");
      return;
    }
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const addCoins = async () => {
    if (!user) {
      toast.error("Error, please sign in first");
      return;
    }

    if (!formData.coins || !formData.companyId) {
      toast.error("Please fill in all required fields");
      return;
    }

    try {
      setAdding(true);
      const idTokenResult = await tokenResult(user);
      const response = await fetch(`${BASE_URL}super-admin/addCoins`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idTokenResult.token}`,
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();

      if (response.status === 200) {
        toast.success(data.message);
        handleClose();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error("Somthing went wrong. Please try again.");
    } finally {
      setAdding(false);
    }
  };

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFormData({ companyId: "", coins: "" });
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        startIcon={<MonetizationOnOutlined />}
        style={{ color: "white" }}
        sx={{
          width: "200px",
          borderRadius: 2,
          textTransform: "none",
          boxShadow: 2,
          "&:hover": {
            boxShadow: 4,
          },
        }}
        onClick={handleClickOpen}
      >
        Add Coins
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: 24,
          }
        }}
      >
        <DialogTitle sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: 1,
          borderColor: "divider",
          pb: 2
        }}>
          <Typography variant="h6" component="div" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <MonetizationOnOutlined color="primary" />
            Add Coins
          </Typography>
          <IconButton onClick={handleClose} size="small">
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box component="form" sx={{ display: "flex", flexDirection: "column", p: 2, gap: 3 }}>
            <TextField
              required
              fullWidth
              label="Company Id"
              name="companyId"
              value={formData.companyId}
              onChange={handleChange}
              variant="outlined"
              placeholder="Enter companyId"
            />
            <TextField
              required
              fullWidth
              label="Coins"
              name="coins"
              type="number"
              value={formData.coins}
              onChange={handleChange}
              variant="outlined"
              placeholder="Enter Coins"
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{
          p: 3,
          borderTop: 1,
          borderColor: "divider",
          gap: 1
        }}>
          <Button
            onClick={handleClose}
            variant="contained"
            style={{ color: "white", background: "rgb(255 73 73)" }}
            startIcon={<Close />}
          >
            Cancel
          </Button>
          <Button
            onClick={addCoins}
            disabled={adding}
            variant="contained"
            style={{ color: "white" }}
            startIcon={<Send />}
            sx={{
              minWidth: "120px",
            }}
          >
            {adding ? "Adding" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}