/* eslint-disable react/prop-types */

import { CircularProgress } from "@mui/material";
import { signOut } from "firebase/auth";
import { auth } from "lib/firebase/firebase";
import { UserAuth } from "provider/AuthProvider";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function SuperAdminLayout({ component }) {
  const [isLoading, setIsLoading] = useState(true);
  const { user, initializing } = UserAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (initializing) return;
    if (!user) {
      navigate("/signin");
      return;
    }
    setIsLoading(false);
  }, [user, initializing]);

  if (isLoading)
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "1rem 5rem 1rem ",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            gap: "2rem",
            backgroundColor: "#e0f7fa",
            border: "3px solid #00796b",
          }}
        >
          <h2 style={{ fontWeight: "500", color: "#00796b" }}>Running Authorization Checks</h2>
          <CircularProgress style={{ height: "40px", width: "40px" }} />
        </div>
      </div>
    );
  return (
    <div style={{ height: "100vh", backgroundColor: "#ebebeb" }}>
      {user?.claims?.superAdmin !== true ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            textAlign: "center",
            color: "#333",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h2
            style={{
              marginBottom: "20px",
              fontSize: "24px",
              fontWeight: "bold",
            }}
          >
            You are not authorized.
          </h2>
          <button
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              color: "#fff",
              backgroundColor: "#e91e62",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={async () => {
              await signOut(auth);
              navigate("/signin");
            }}
          >
            Logout
          </button>
        </div>
      ) : (
        <div>{component}</div>
      )}
    </div>
  );
}

export default SuperAdminLayout;
