import { constants } from "lib/utilities/Auth";
import { z } from "zod";
const { regex } = constants;

export const signupSchema = z.object({
  fullName: z
    .string()
    .min(2, "Name must be at least 2 characters")
    .max(50, `Name can't be more than 50 characters`),
  gender: z.string().min(2, "Select a gender").max(50, `Gender can't be more than 50 characters`),
  phone: z
    .string()
    .min(10, "Phone number must be at least 10 characters")
    .max(10, "Phone number must be at most 10 characters")
    .transform((value) => {
      return value.replace(regex.bannedSymbols.phoneNumber, "");
    })
    .refine((value) => value.match(regex.validation.phoneNumber), {
      message: "Invalid phone number",
    }),
  email: z.string().email(),
  password: z
    .string()
    .min(6, "Password must be at least 6 characters")
    .max(50, `Password can't be more than 50 characters`),
});

export const techyrrAdminSignupSchema = z.object({
  fullName: z
    .string()
    .min(2, "Name must be at least 2 characters")
    .max(50, `Name can't be more than 50 characters`),
  company: z
    .string()
    .min(2, "Company name must be at least 2 characters")
    .max(50, `Company name can't be more than 50 characters`),
  gender: z.string().min(2, "Select a gender").max(50, `Gender can't be more than 50 characters`),
  phone: z
    .string()
    .min(10, "Phone number must be at least 10 characters")
    .max(10, "Phone number must be at most 10 characters")
    .transform((value) => {
      return value.replace(regex.bannedSymbols.phoneNumber, "");
    })
    .refine((value) => value.match(regex.validation.phoneNumber), {
      message: "Invalid phone number",
    }),
  email: z.string().email(),
  password: z
    .string()
    .min(6, "Password must be at least 6 characters")
    .max(50, `Password can't be more than 50 characters`),
});
