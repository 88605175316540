import { CircularProgress } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { BASE_URL } from "lib/utilities/globalConstants";
import { UserAuth } from "provider/AuthProvider";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import InviteAdmin from "../../../customComponents/Modals/InviteAdmin";
import AddCoinsModel from "customComponents/Modals/addCoinsModel";

export default function SuperDashboard() {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true); // State to track if more admins are available
  const { user } = UserAuth();
  const limit = 6;
  const { ref, inView } = useInView({ threshold: 0.5 });

  async function fetchAdmins() {
    if (!user) return;

    try {
      const idTokenResult = await user.getIdTokenResult();
      // console.log(idTokenResult);
      const response = await fetch(
        `${BASE_URL}super-admin/getAllAdmins?offset=${offset}&limit=${limit}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idTokenResult.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      // console.log(data);
      setAdmins((prevAdmins) => [...prevAdmins, ...data.filteredArray]);
      setOffset((prevOffset) => prevOffset + limit);
      setLoading(false);

      // Check if more admins are available
      if (data.filteredArray.length < limit) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching admins:", error);
      setLoading(false);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await fetchAdmins();
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    if (inView && !loading && hasMore) {
      fetchAdmins();
    }
  }, [inView, loading, hasMore]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", padding: "30px 40px 0px", gap: "15px" }}
    >
      <div style={{ display: "flex", justifyContent: "end", margin: "0px 20px 0px" }}>
        <div className="mr-5">
          <AddCoinsModel />
        </div>
        <div>
          <InviteAdmin />
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ display: "flex" }}>
              <TableCell sx={{ flex: 2 }}>ID</TableCell>
              <TableCell sx={{ flex: 2 }} align="center">
                Name
              </TableCell>
              <TableCell sx={{ flex: 2 }} align="center">
                Email
              </TableCell>
              <TableCell sx={{ flex: 2 }} align="center">
                Role
              </TableCell>
              <TableCell sx={{ flex: 2 }} align="center">
                Licences
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              admins?.map((admin) => (
                <TableRow
                  key={admin.uid}
                  sx={{ display: "flex", "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={{ flex: 1 }} component="th" scope="row">
                    {admin.uid}
                  </TableCell>
                  <TableCell sx={{ flex: 1 }} align="center">
                    {admin.displayName}
                  </TableCell>
                  <TableCell sx={{ flex: 1 }} align="center">
                    {admin.email}
                  </TableCell>
                  <TableCell sx={{ flex: 1 }} align="center">
                    {admin.admin && "ADMIN"}
                  </TableCell>
                  <TableCell sx={{ flex: 1 }} align="center">
                    {admin.licences.join(", ")}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <span ref={ref} style={{ visibility: "hidden" }}>
          intersection observer marker
        </span>
      </TableContainer>
    </div>
  );
}
