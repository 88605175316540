import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { MenuItem, Select, Skeleton } from "@mui/material";
import {
  TrendingUp,
  TrendingDown,
  CalendarToday,
  ArrowBack,
  Pause,
  ChevronRightOutlined,
  CalendarMonthOutlined,
  EqualizerOutlined,
} from "@mui/icons-material";
import { BASE_URL } from "lib/utilities/globalConstants";
import { UserAuth } from "provider/AuthProvider";
import TransactionList from "customComponents/TransactionList";

const TransactionHistory = () => {
  const { user } = UserAuth();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const { data: firstYearData } = useQuery({
    queryKey: ["firstTransactionYear"],
    queryFn: async () => {
      const response = await fetch(`${BASE_URL}admin/getFirstTransactionYear`, {
        headers: { Authorization: `Bearer ${user.accessToken}` },
      });
      return response.json();
    },
  });

  const { data: monthlyData, isLoading: isMonthlyLoading } = useQuery({
    queryKey: ["monthlyTransactions", selectedYear],
    queryFn: async () => {
      const response = await fetch(
        `${BASE_URL}admin/getMonthlyTransactionsByYear/${selectedYear}`,
        { headers: { Authorization: `Bearer ${user.accessToken}` } }
      );
      return response.json();
    },
  });

  const { data: dailyData, isLoading: isDailyLoading } = useQuery({
    queryKey: ["dailyTransactions", selectedMonth, selectedYear, page, rowsPerPage],
    enabled: !!selectedMonth,
    queryFn: async () => {
      const response = await fetch(
        `${BASE_URL}admin/getDailyTransactionByMonth?month=${selectedMonth}&year=${selectedYear}&page=${page + 1
        }&perPage=${rowsPerPage}`,
        { headers: { Authorization: `Bearer ${user.accessToken}` } }
      );
      return response.json();
    },
  });

  const years = firstYearData?.data
    ? Array.from(
      { length: new Date().getFullYear() - firstYearData.data + 1 },
      (_, i) => firstYearData.data + i
    )
    : [];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="flex flex-1 w-full h-full flex-col">
      <div className="bg-white border-b sticky top-0 z-40 shadow-sm">
        <div className="px-6">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center space-x-4">
              <span className="text-lg font-semibold text-gray-900">Transaction History</span>
              {selectedMonth && (
                <button
                  onClick={() => setSelectedMonth(null)}
                  className="flex items-center space-x-2 px-3 py-1.5 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg transition-colors border border-gray-200"
                >
                  <ArrowBack style={{ width: "16px", height: "16px" }} />
                  <span className="text-sm font-medium">Back to Monthly View</span>
                </button>
              )}
              {!selectedMonth && (
                <div className="relative" ref={dropdownRef}>
                  <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="flex items-center space-x-2 px-3 py-1.5 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
                  >
                    <CalendarToday
                      className="text-gray-500"
                      style={{ width: "16px", height: "16px" }}
                    />
                    <span className="text-sm font-medium text-gray-700">{selectedYear}</span>
                    <svg
                      className={`w-4 h-4 text-gray-500 transition-transform ${isOpen ? "rotate-180" : ""
                        }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  {isOpen && (
                    <div className="absolute top-full left-0 mt-1 w-full bg-white border border-gray-200 rounded-lg shadow-lg py-1 z-50">
                      {years.map((year) => (
                        <button
                          key={year}
                          onClick={() => {
                            setSelectedYear(year);
                            setIsOpen(false);
                          }}
                          className={`w-full px-4 py-2 text-sm text-left hover:bg-gray-50 ${year === selectedYear ? "bg-blue-50 text-blue-600" : "text-gray-700"
                            }`}
                        >
                          {year}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="flex items-center space-x-2 text-sm text-gray-600">
              <span>{selectedMonth ? months[selectedMonth - 1] : "All months"}</span> <span>•</span>
              <span>{selectedYear}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="p-6">
        {!selectedMonth ? (
          <div className="grid gap-8">
            {isMonthlyLoading
              ? [...Array(6)].map((_, i) => <MonthlyRowSkeleton key={i} />)
              : monthlyData?.data?.length > 0 ? (
                monthlyData?.data?.map((month) => (
                <div
                  key={month.month}
                  onClick={() => {
                    setSelectedMonth(month.month);
                    setPage(0);
                  }}
                  className="group relative bg-white rounded-2xl border border-gray-100 hover:border-blue-200 shadow-sm hover:shadow-lg transition-all duration-300 ease-out overflow-hidden cursor-pointer"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-50/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  <div className="relative p-8">
                    <div className="flex items-center justify-between mb-8">
                      <div className="flex items-center space-x-4">
                        <div className="bg-blue-50 rounded-xl p-4 group-hover:bg-blue-100 transition-colors duration-300">
                          <CalendarMonthOutlined className="w-6 h-6 text-blue-600" />
                        </div>
                        <div>
                          <h3 className="text-2xl font-bold text-gray-900">
                            {months[month.month - 1]} {selectedYear}
                          </h3>
                          <p className="text-sm text-gray-500 mt-1">Monthly Statement</p>
                        </div>
                      </div>
                      <div className="flex items-center space-x-2">
                        <ChevronRightOutlined className="w-5 h-5 text-gray-400 group-hover:text-blue-500 group-hover:translate-x-1 transition-all duration-300" />
                      </div>
                    </div>
                    <div className="grid grid-cols-4 gap-8">
                      <div className="space-y-6">
                        <div className="p-4 bg-green-50 rounded-xl">
                          <div className="flex items-center justify-between mb-2">
                            <p className="text-sm font-medium text-gray-600">Total Credits</p>
                            <TrendingUp className="w-4 h-4 text-green-600" />
                          </div>
                          <p className="text-2xl font-bold text-green-600">
                            ₹{new Intl.NumberFormat("en-IN").format(month.amount_add)}
                          </p>
                          <p className="text-sm text-gray-500 mt-1">{month.coins_add} coins</p>
                        </div>
                      </div>
                      <div className="space-y-6">
                        <div className="p-4 bg-red-50 rounded-xl">
                          <div className="flex items-center justify-between mb-2">
                            <p className="text-sm font-medium text-gray-600">Total Debits</p>
                            <TrendingDown className="w-4 h-4 text-red-600" />
                          </div>
                          <p className="text-2xl font-bold text-red-600">
                            ₹{new Intl.NumberFormat("en-IN").format(month.amount_spent)}
                          </p>
                          <p className="text-sm text-gray-500 mt-1">{month.coins_spent} coins</p>
                        </div>
                      </div>
                      <div className="space-y-6">
                        <div className="p-4 bg-orange-50 rounded-xl">
                          <div className="flex items-center justify-between mb-2">
                            <p className="text-sm font-medium text-gray-600">On Hold</p>
                            <Pause className="w-4 h-4 text-orange-600" />
                          </div>
                          <p className="text-2xl font-bold text-orange-600">
                            ₹{new Intl.NumberFormat("en-IN").format(month.amount_hold)}
                          </p>
                          <p className="text-sm text-gray-500 mt-1">{month.coins_hold} coins</p>
                        </div>
                      </div>
                      <div className="space-y-6">
                        <div className="p-4 bg-blue-50 rounded-xl">
                          <div className="flex items-center justify-between mb-2">
                            <p className="text-sm font-medium text-gray-600">Net Balance</p>
                            <EqualizerOutlined className="w-4 h-4 text-blue-600" />
                          </div>
                          <p
                            className={`text-2xl font-bold ${month.amount_add - month.amount_spent >= 0
                              ? "text-green-600"
                              : "text-red-600"
                              }`}
                          >
                            ₹
                            {new Intl.NumberFormat("en-IN").format(
                              month.amount_add - month.amount_spent
                            )}
                          </p>
                          <p className="text-sm text-gray-500 mt-1">
                            {month.coins_add - month.coins_spent} coins
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                ))) : (
                <NoDataState selectedYear={selectedYear} />
              )}
          </div>
        ) : (
          <div className="space-y-6">
            {(() => {
              const month = monthlyData?.data?.find((m) => m.month === selectedMonth);
              if (!month) return null;
              const formatAmount = (amount) => new Intl.NumberFormat("en-IN").format(amount);
              const netBalance = month.amount_add - month.amount_spent;
              return (
                <div className="grid grid-cols-4 gap-8">
                  <div className="space-y-6">
                    <div className="p-4 bg-green-50 rounded-xl">
                      <div className="flex items-center justify-between mb-2">
                        <p className="text-sm font-medium text-gray-600">Total Credits</p>
                        <TrendingUp className="w-4 h-4 text-green-600" />
                      </div>
                      <p className="text-2xl font-bold text-green-600">
                        ₹{formatAmount(month.amount_add)}
                      </p>
                      <p className="text-sm text-gray-500 mt-1">{month.coins_add} coins</p>
                    </div>
                  </div>
                  <div className="space-y-6">
                    <div className="p-4 bg-red-50 rounded-xl">
                      <div className="flex items-center justify-between mb-2">
                        <p className="text-sm font-medium text-gray-600">Total Debits</p>
                        <TrendingDown className="w-4 h-4 text-red-600" />
                      </div>
                      <p className="text-2xl font-bold text-red-600">
                        ₹{formatAmount(month.amount_spent)}
                      </p>
                      <p className="text-sm text-gray-500 mt-1">{month.coins_spent} coins</p>
                    </div>
                  </div>
                  <div className="space-y-6">
                    <div className="p-4 bg-orange-50 rounded-xl">
                      <div className="flex items-center justify-between mb-2">
                        <p className="text-sm font-medium text-gray-600">On Hold</p>
                        <Pause className="w-4 h-4 text-orange-600" />
                      </div>
                      <p className="text-2xl font-bold text-orange-600">
                        ₹{formatAmount(month.amount_hold)}
                      </p>
                      <p className="text-sm text-gray-500 mt-1">{month.coins_hold} coins</p>
                    </div>
                  </div>
                  <div className="space-y-6">
                    <div className="p-4 bg-blue-50 rounded-xl">
                      <div className="flex items-center justify-between mb-2">
                        <p className="text-sm font-medium text-gray-600">Net Balance</p>
                        <EqualizerOutlined className="w-4 h-4 text-blue-600" />
                      </div>
                      <p
                        className={`text-2xl font-bold ${netBalance >= 0 ? "text-green-600" : "text-red-600"
                          }`}
                      >
                        ₹{formatAmount(Math.abs(netBalance))}
                      </p>
                      <p className="text-sm text-gray-500 mt-1">
                        {month.coins_add - month.coins_spent} coins
                      </p>
                    </div>
                  </div>
                </div>
              );
            })()}
            <div className="mx-auto bg-white rounded-lg border border-gray-200 divide-y divide-gray-100">
              <TransactionList transactions={dailyData?.data?.transactions} isLoading={isDailyLoading} rowsPerPage={rowsPerPage} />
            </div>
            <div className="flex justify-between items-center px-4 py-3 bg-white rounded-lg shadow-sm border">
              <div className="flex items-center space-x-2">
                <span className="text-sm text-gray-600">Rows per page:</span>
                <Select
                  value={rowsPerPage}
                  onChange={(e) => {
                    setRowsPerPage(e.target.value);
                    setPage(0);
                  }}
                  className="h-8"
                >
                  {[5, 10, 20, 30].map((n) => (
                    <MenuItem key={n} value={n}>
                      {n}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="flex items-center space-x-4">
                <button
                  onClick={() => setPage((prev) => Math.max(0, prev - 1))}
                  disabled={page === 0}
                  className="px-3 py-1 text-sm text-gray-600 hover:bg-gray-100 rounded disabled:opacity-50"
                >
                  Previous
                </button>
                <span className="text-sm text-gray-600">
                    Page {page + 1} of{" "}
                  {Math.ceil((dailyData?.data?.pagination?.total || 0) / rowsPerPage)}
                </span>
                <button
                  onClick={() => setPage((prev) => prev + 1)}
                  disabled={
                    page >= Math.ceil((dailyData?.data?.pagination?.total || 0) / rowsPerPage) - 1
                  }
                  className="px-3 py-1 text-sm text-gray-600 hover:bg-gray-100 rounded disabled:opacity-50"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default TransactionHistory;

const MonthlyRowSkeleton = () => (
  <div className="bg-white rounded-2xl border border-gray-100 shadow-sm overflow-hidden">
    <div className="relative p-8">
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center space-x-4">
          <Skeleton
            variant="rounded"
            width={56}
            height={56}
            animation="wave"
            className="rounded-xl"
          />
          <div>
            <Skeleton
              variant="text"
              width={192}
              height={32}
              animation="wave"
              className="mb-2"
            />
            <Skeleton
              variant="text"
              width={128}
              height={16}
              animation="wave"
            />
          </div>
        </div>
        <Skeleton
          variant="circular"
          width={20}
          height={20}
          animation="wave"
        />
      </div>

      <div className="grid grid-cols-4 gap-8">
        {[...Array(4)].map((_, index) => (
          <div key={index} className="space-y-6">
            <div className="p-4 bg-gray-50 rounded-xl">
              <div className="flex items-center justify-between mb-2">
                <Skeleton
                  variant="text"
                  width={96}
                  height={16}
                  animation="wave"
                />
                <Skeleton
                  variant="circular"
                  width={16}
                  height={16}
                  animation="wave"
                />
              </div>
              <Skeleton
                variant="rectangular"
                width="100%"
                height={32}
                animation="wave"
                className="mb-2 rounded-md"
              />
              <Skeleton
                variant="text"
                width={80}
                height={16}
                animation="wave"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const NoDataState = ({ selectedYear }) => (
  <div className="flex flex-col items-center justify-center p-12 bg-white rounded-2xl border border-gray-100">
    <div className="bg-gray-50 rounded-xl p-4 mb-4">
      <CalendarMonthOutlined className="w-8 h-8 text-gray-400" />
    </div>
    <h3 className="text-lg font-semibold text-gray-900 mb-2">
      No Transactions Found
    </h3>
    <p className="text-sm text-gray-500 text-center max-w-sm">
      There are no transactions recorded for {selectedYear}.
      Transactions will appear here once they are made.
    </p>
  </div>
);