export const getCoinsFromExperience = (experience, experiencePrice) => {
  const pricing = experiencePrice.find(p => p.experience === experience);
  return pricing.coins;
};

export const getCustomSkillsCost = (customSkillsCount, coinPrice, skillPrice) => {
  if (customSkillsCount === 0) return { totalCustomCoins: 0, totalCustomAmount: 0 };
  const totalCustomAmount = customSkillsCount * skillPrice;
  const totalCustomCoins = (totalCustomAmount / coinPrice);
  return { totalCustomCoins, totalCustomAmount };
};

export const calculateTotalCost = (totalCandidate, experience, experiencePrice, coinPrice, skillPrice = 0, customSkillsCount = 0) => {
  const baseCoins = getCoinsFromExperience(experience, experiencePrice);
  const baseAmount = baseCoins * coinPrice;

  const { totalCustomCoins, totalCustomAmount } = getCustomSkillsCost(customSkillsCount, coinPrice, skillPrice);

  const totalCoins = (baseCoins + totalCustomCoins) * totalCandidate;
  const totalAmount = (baseAmount + totalCustomAmount) * totalCandidate;

  return {
    baseCoins,
    baseAmount,
    totalCoins,
    totalAmount,
    customSkillsCoins: totalCustomCoins,
    customSkillsAmount: totalCustomAmount
  };
};