import {
  TrendingUp,
  TrendingDown,
  Add,
  Pause,
  CalendarMonthOutlined,
  AccessTimeOutlined,
  CurrencyExchange,
  Error,
} from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import { useMemo } from "react";

const TRANSACTION_COLORS = {
  SPENT: { base: "bg-red-100 text-red-700 ring-1 ring-red-200", text: "text-red-600" },
  ADD: { base: "bg-green-100 text-green-700 ring-1 ring-green-200", text: "text-green-600" },
  HOLD: { base: "bg-amber-100 text-amber-700 ring-1 ring-amber-200", text: "text-amber-600" },
  RELEASE: { base: "bg-blue-100 text-blue-700 ring-1 ring-blue-200", text: "text-blue-600" },
  REFUND: { base: "bg-teal-100 text-teal-700 ring-1 ring-teal-200", text: "text-teal-600" },
  FAIL: { base: "bg-red-100 text-red-700 ring-1 ring-red-200", text: "text-red-600" },
};

const TransactionIcon = ({ type }) => {
  const icons = {
    SPENT: <TrendingDown className="w-5 h-5" />,
    ADD: <TrendingUp className="w-5 h-5" />,
    HOLD: <Pause className="w-5 h-5" />,
    RELEASE: <Add className="w-5 h-5" />,
    REFUND: <CurrencyExchange className="w-5 h-5" />,
    FAIL: <Error className="w-5 h-5" />,
  };

  return icons[type] || null;
};

const StatusBadge = ({ type }) => {
  const config = {
    SPENT: { color: "bg-red-50 text-red-700 ring-1 ring-red-200", label: "Spent" },
    ADD: { color: "bg-green-50 text-green-700 ring-1 ring-green-200", label: "Added" },
    HOLD: { color: "bg-amber-50 text-amber-700 ring-1 ring-amber-200", label: "On Hold" },
    RELEASE: { color: "bg-blue-50 text-blue-700 ring-1 ring-blue-200", label: "Released" },
    REFUND: { color: "bg-teal-50 text-teal-700 ring-1 ring-teal-200", label: "Refunded" },
    FAIL: { color: "bg-red-50 text-red-700 ring-1 ring-red-200", label: "Failed" },
  }[type] || { color: "bg-gray-50 text-gray-700 ring-1 ring-gray-200", label: type };

  return (
    <span className={`${config.color} text-xs font-medium px-3 py-1 rounded-full shadow-sm`}>
      {config.label}
    </span>
  );
};

const TransactionItem = ({ transaction }) => {
  const [date, time] = useMemo(() => {
    const dt = new Date(transaction.created_at);
    return [
      dt.toLocaleDateString("en-US", { day: "numeric", month: "short", year: "numeric" }),
      dt.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" }),
    ];
  }, [transaction.created_at]);
  const isSpent = ["SPENT", "HOLD"].includes(transaction.transaction_subtype);
  const isPositive = ["ADD", "RELEASE", "REFUND"].includes(transaction.transaction_subtype);

  return (
    <div className="group px-4 py-2 hover:bg-gray-50/90 transition-all duration-200">
      <div className="flex items-center gap-6">
        <div
          className={`rounded-xl p-3.5 flex items-center justify-center h-12 w-1h-12 transition-all duration-300 transform 
        group-hover:scale-105 shadow-sm ${TRANSACTION_COLORS[transaction.transaction_subtype]?.base
            }`}
        >
          <TransactionIcon type={transaction.transaction_subtype} />
        </div>
        <div className="flex-grow space-y-2">
          <StatusBadge type={transaction.transaction_subtype} />
          <div className="flex items-center gap-6 text-xs text-gray-600">
            <span className="flex items-center">
              <CalendarMonthOutlined className="w-3.5 h-3.5 mr-1.5" />
              {date}
            </span>
            <span className="flex items-center">
              <AccessTimeOutlined className="w-3.5 h-3.5 mr-1.5" />
              {time}
            </span>
          </div>
        </div>
        <div className="flex items-center gap-10">
          <div className="text-right">
            <p
              className={`text-lg font-semibold tracking-tight ${TRANSACTION_COLORS[transaction.transaction_subtype]?.text
                }
              }`}
            >
              {isSpent ? "- " : isPositive ? "+ " : ""}₹
              {new Intl.NumberFormat("en-IN").format(transaction.amount)}
            </p>
            <p className="text-xs text-gray-500 mt-0.5">
              Balance: ₹{new Intl.NumberFormat("en-IN").format(transaction.balance_after)}
            </p>
          </div>
          <div className="text-right min-w-[120px]">
            <p
              className={`text-lg font-semibold tracking-tight ${TRANSACTION_COLORS[transaction.transaction_subtype]?.text
                }`}
            >
              {isSpent ? "- " : isPositive ? "+ " : ""}
              {transaction.coin}
              <span className="text-xs font-medium ml-1">COINS</span>
            </p>
            <p className="text-xs text-gray-500 mt-0.5">Balance: {transaction.coins_after} coins</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const TransactionListSkeleton = ({ rowsPerPage }) => (
  <div className="divide-y divide-gray-100">
    {[...Array(rowsPerPage)].map((_, i) => (
      <div key={i} className="px-4 py-2">
        <div className="flex items-center gap-6">
          <Skeleton
            variant="rounded"
            width={60}
            height={60}
            animation="wave"
            className="rounded-xl"
          />
          <div className="flex-grow space-y-2">
            <Skeleton
              variant="rounded"
              width={80}
              height={20}
              animation="wave"
              className="rounded-full"
            />
            <div className="flex items-center gap-6">
              <Skeleton
                variant="text"
                width={120}
                height={20}
                animation="wave"
              />
              <Skeleton
                variant="text"
                width={100}
                height={20}
                animation="wave"
              />
            </div>
          </div>
          <div className="flex items-center gap-10">
            <div className="text-right">
              <Skeleton
                variant="text"
                width={120}
                height={32}
                animation="wave"
              />
              <Skeleton
                variant="text"
                width={100}
                height={20}
                animation="wave"
              />
            </div>
            <div className="text-right min-w-[120px]">
              <Skeleton
                variant="text"
                width={120}
                height={32}
                animation="wave"
              />
              <Skeleton
                variant="text"
                width={100}
                height={20}
                animation="wave"
              />
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
);

const TransactionList = ({ transactions, isLoading, rowsPerPage }) => {
  return (
    <div className="bg-white rounded-lg overflow-hidden">
      <div className="border-b bg-gray-50/80 p-4">
        <h2 className="text-lg font-semibold text-gray-800">Transaction History</h2>
      </div>
      {isLoading ? (
        <TransactionListSkeleton rowsPerPage={rowsPerPage} />
      ) : (
        <div className="divide-y divide-gray-100">
          {transactions.map((transaction) => (
            <TransactionItem key={transaction.id} transaction={transaction} />
          ))}
        </div>
      )}
    </div>
  );
};

export default TransactionList;
